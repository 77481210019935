import React from 'react';
import Home from './Home';
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
import Login from './Login';
import Logout from './Logout'
import AddEvent from './AddEvent'
import EditEvent from './EditEvent';
import EventList from './EventList';
import StringerList from './StringerList';
import AddStringer from './AddStringer'
import EditStringer from './EditStringer';
import StringerProfile from './StringerProfile'
import StringerDailyOutputGraph from './StringerDailyOutputGraph'
import StateHistogram from './StateHistogram'
import Test from './Test';
const App = () =>{
  return(
    <>
    <BrowserRouter>
    <Switch>

      <Route exact path="/login" component={Login}/>
      <Route exact path="/test" component={Test}/>
      <Route exact path="/logout" component={Logout}/>   
      <Route exact path="/" component={Home}/>
      <Route exact path="/user-profile/:editId" component={StringerProfile}/>
      <Route exact path="/add-stringer" component={AddStringer}/>
      <Route exact path="/edit-stringer/:editId" component={EditStringer}/>
      <Route exact path="/stringer-list" component={StringerList}/>
      <Route exact path="/add-event" component={AddEvent}/>
      <Route exact path="/edit-event/:editId" component={EditEvent}/>
      <Route exact path="/event-list" component={EventList}/>
      <Route exact path="/stringer-graph" component={StringerDailyOutputGraph}/>
      <Route exact path= "/graphs" component={StateHistogram}/>
      <Redirect to="/" />

    </Switch>
    </BrowserRouter>
    </>
  )
}

export default App;
