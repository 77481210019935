import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CanvasJSReact from '@canvasjs/react-charts'; // Import CanvasJS

// Import the required components from CanvasJS
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const StateHistogram = (props) => {
  const [data, setData] = useState(null);   // State to hold the API response
  const [loading, setLoading] = useState(true);   // Loading state
  const [error, setError] = useState(null);  // Error state
  const [currentMonth, setCurrentMonth] = useState('');
  const [currentYear, setCurrentYear] = useState('');
  const [singleData, setSingleData] = useState({
    total_single_id : null,
    total_multi_id:null,
    total_on_demand: null,
    total_events : null
  })
  // Use useEffect to fetch data from the API when the component mounts
  useEffect(() => {

    const today = new Date(); // Get current date
    let month = today.getMonth(); // Get current month (0 = January, 1 = February, ..., 11 = December)
    let year = today.getFullYear(); // Get current year

    if (today.getDate() === 1) {
      // If today is the first day of the month, use the previous month
      month = month === 0 ? 11 : month - 1; // Adjust to December if current month is January
      year = month === 11 ? year - 1 : year; // Adjust to previous year if month is December
    }

    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June', 'July',
      'August', 'September', 'October', 'November', 'December'
    ];
    // Fetch the data from the API
    let apiUr = `https://stingers.ianswire.in/api/state_stinger_report/`;
    if (props && props.startDate && props.endDate) {
        apiUr = apiUr + `?start_date=${props.startDate}&end_date=${props.endDate}`;
    }
    axios
      .get(apiUr, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken : ''}`,
      }})
      .then((response) => {
        setCurrentMonth(monthNames[month]);
        setCurrentYear(year);
        let singleData = {};
        if(response.data && localStorage && localStorage.length > 0 && localStorage.access==40){
          setSingleData({
            "total_single_id": response.data.overall_totals.total_single_id*500,
            "total_multi_id": response.data.overall_totals.total_multi_id*300,
            "total_on_demand": response.data.overall_totals.total_on_demand*750,
            "total_events": response.data.overall_totals.total_multi_id*300+response.data.overall_totals.total_on_demand*750+response.data.overall_totals.total_single_id*500
          }); 
          setData(response.data);  // Store the data in state
        }else{
          setData(response.data);  // Store the data in state
        }
       

        setLoading(false);  // Turn off loading once the data is fetched
      })
      .catch((err) => {
        setError('Error fetching data');  // Store any errors
        setLoading(false);
        
       
      });
  }, [props]);  // The empty array ensures this effect runs only once after the component mounts

  // Loading and error states
  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  // Prepare the chart data for CanvasJS
  const chartOptions = {
    animationEnabled: true,
    axisY: {
      title: "Event Counts",
      includeZero: true
    },
    toolTip: {
      shared: true,
      reversed: true
    },
    legend: {
      verticalAlign: "center",
      horizontalAlign: "right",
      reversed: true,
      cursor: "pointer"
    },
    data: [
      {
        type: "stackedColumn",
        name: "Single ID",
        // showInLegend: true,
        yValueFormatString: "#,###",
        color: "#8884d8", // Custom color for Single ID
        dataPoints: Object.keys(data.state_summary).map(state => ({
          label: state,
          y: data.state_summary[state].single_id
        }))
      },
      {
        type: "stackedColumn",
        name: "Multi ID",
        // showInLegend: true,
        yValueFormatString: "#,###",
        color: "#df9b62", // Custom color for Multi ID
        dataPoints: Object.keys(data.state_summary).map(state => ({
          label: state,
          y: data.state_summary[state].multi_id
        }))
      },
      {
        type: "stackedColumn",
        name: "On Demand",
        // showInLegend: true,
        yValueFormatString: "#,###",
        color: "#cb66c4", // Custom color for On Demand
        dataPoints: Object.keys(data.state_summary).map(state => ({
          label: state,
          y: data.state_summary[state].on_demand
        }))
      },
      // {
      //   type: "stackedColumn",
      //   name: "Total",
      //   // showInLegend: true,
      //   yValueFormatString: "#,###",
      //   color: "#df9b62", // Custom color for Total
      //   dataPoints: Object.keys(data.state_summary).map(state => ({
      //     label: state,
      //     y: data.state_summary[state].total
      //   }))
      // }
    ]
  };

  return (
    <div className='State-graphs'>
      <div className="row">

        <div class="counter col-lg-6">
            <div className="row">
                <h2 className="text-center"></h2>
                <div className="col-6 col-lg-3 flip-container" ontouchstart="this.classList.toggle('hover');">	
                  <div className="basic-card basic-card-lips">	
                      {/* FRONT START */}
                      <div className="front">			
                          <div className="count-data text-center card-content">
                              <h6 className="count h2" data-to={singleData && singleData.total_events && singleData.total_events > 0 ? singleData.total_events : data && data.overall_totals ? data.overall_totals.total_events: 0
                              } data-speed={singleData && singleData.total_events && singleData.total_events > 0 ? singleData.total_events : data &&data.overall_totals ? data.overall_totals.total_events: 0
                              }>{singleData && singleData.total_events && singleData.total_events > 0 ? singleData.total_events : data &&data.overall_totals ? data.overall_totals.total_events: 0
                              }</h6>
                          </div>
                          <div className="card-link">
                              <p className="m-0px font-w-600">Total</p>
                          </div>
                      </div>
                      {/* FRONT END */}
                      {/* BACK START */}
                      <div class="back">
                        <div class="count-data text-center card-content">
                          <h6 class="count h2" data-to="48700" data-speed="48700">48700</h6>
                        </div>
                        <div class="card-link">
                          <p class="m-0px font-w-600">Total Amount</p>
                        </div>
                      </div>
                    {/* BACK END */}
                  </div>
                </div>
                <div className="col-6 col-lg-3 flip-container" ontouchstart="this.classList.toggle('hover');">
                    <div className="basic-card basic-card-single">	
                          {/* FRONT END */}
                          <div className="front">
                              <div className="count-data text-center card-content">
                              <h6 className="count h2" data-to={singleData && singleData.total_single_id && singleData.total_single_id > 0 ? singleData.total_single_id : data &&data.overall_totals ? data.overall_totals.total_single_id: 0
                                  } data-speed={singleData && singleData.total_single_id && singleData.total_single_id > 0 ? singleData.total_single_id : data &&data.overall_totals ? data.overall_totals.total_single_id: 0
                                  }>{singleData && singleData.total_single_id && singleData.total_single_id > 0 ? singleData.total_single_id : data &&data.overall_totals ? data.overall_totals.total_single_id: 0
                                  }</h6>
                              </div>
                              <div className="card-link">
                                  <p className="m-0px font-w-600">Single ID</p>
                              </div>
                          </div>
                          {/* FRONT END */}
                          {/* BACK START */}
                          <div class="back">
                            <div class="count-data text-center card-content">
                              <h6 class="count h2" data-to="48700" data-speed="48700">10000</h6>
                            </div>
                            <div class="card-link">
                              <p class="m-0px font-w-600">Amount</p>
                            </div>
                          </div>
                        {/* BACK END */}
                    </div>
                </div>
                <div className="col-6 col-lg-3 flip-container" ontouchstart="this.classList.toggle('hover');">
                  <div className="basic-card basic-card-multi">	
                          {/* FRONT END */}
                          <div className="front">
                              <div className="count-data text-center card-content">
                              <h6 className="count h2" data-to={singleData && singleData.total_multi_id && singleData.total_multi_id > 0 ? singleData.total_multi_id : data &&data.overall_totals ? data.overall_totals.total_multi_id: 0
                                  } data-speed={singleData && singleData.total_multi_id && singleData.total_multi_id > 0 ? singleData.total_multi_id : data &&data.overall_totals ? data.overall_totals.total_multi_id: 0
                                  }>{singleData && singleData.total_multi_id && singleData.total_multi_id > 0 ? singleData.total_multi_id : data &&data.overall_totals ? data.overall_totals.total_multi_id: 0
                                  }</h6>
                              </div>
                              <div className="card-link">
                                  <p className="m-0px font-w-600">Multi ID</p>
                              </div>
                          </div>
                          {/* FRONT END */}
                          {/* BACK START */}
                          <div class="back">
                            <div class="count-data text-center card-content">
                              <h6 class="count h2" data-to="48700" data-speed="48700">35000</h6>
                            </div>
                            <div class="card-link">
                              <p class="m-0px font-w-600">Amount</p>
                            </div>
                          </div>
                        {/* BACK END */}
                    </div>
                </div>
                <div className="col-6 col-lg-3 flip-container" ontouchstart="this.classList.toggle('hover');">
                    <div className="basic-card basic-card-on-demand">	
                          <div className="front">
                              <div className="count-data text-center card-content">
                              <h6 className="count h2" data-to={singleData && singleData.total_on_demand && singleData.total_on_demand > 0 ? singleData.total_on_demand : data &&data.overall_totals ? data.overall_totals.total_on_demand: 0
                                  } data-speed={singleData && singleData.total_on_demand && singleData.total_on_demand > 0 ? singleData.total_on_demand : data &&data.overall_totals ? data.overall_totals.total_on_demand: 0
                                  }>{singleData && singleData.total_on_demand && singleData.total_on_demand > 0 ? singleData.total_on_demand : data &&data.overall_totals ? data.overall_totals.total_on_demand: 0}</h6>
                              </div>
                              <div className="card-link">
                                  <p className="m-0px font-w-600">On Demand</p>
                              </div>
                          </div>
                          {/* FRONT END */}
                          {/* BACK START */}
                          <div class="back">
                            <div class="count-data text-center card-content">
                              <h6 class="count h2" data-to="48700" data-speed="48700">3750</h6>
                            </div>
                            <div class="card-link">
                              <p class="m-0px font-w-600">Amount</p>
                            </div>
                          </div>
                        {/* BACK END */}
                    </div>
                </div>


            </div>
        </div>
      </div>
      {/* Render the CanvasJSChart */}
      <CanvasJSChart options={chartOptions} />
    </div>
  );
};

export default StateHistogram;
