import React,{useState, useEffect,useRef} from 'react';
import axios from 'axios';
import Header from './Header'
import Footer from './Footer'
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { useHistory } from 'react-router-dom';
import API_URL from './ApiData'
import Sidebar from './Sidebar';

const EventList=()=>{
    const [msg, setMsg]= useState('')
    const history = useHistory()
    const [userData, setUserData] = useState()
    const inputRef = useRef(null)

    const [strinData, setStringData] = useState()
    const[inputData, setInputData] = useState({
      date:  '',
      event_type:'',
      added_by: localStorage && localStorage.username ? localStorage.username : '',
      approved_by: null,
      stringers:[],
      remarks: '',
      slug: ''
  })
 

  const handleOnChange = event => {
    const { name, value } = event.target;
    setInputData({ ...inputData, [name]: value });
  }

  const SaveStoryData = ()=>{
    if(!inputData.slug){
        alert('Slug can not be blank.');
        return false;
    }else if(!inputData.remarks){
        alert('Remarks can not be blank.')
        return false
    }else if(!inputData.event_type){
        alert('Event Type can not be blank.')
        return false
    }else if(!inputData.approved_by){
        alert('Approved by can not be blank.')
        return false
    }else if(!inputData.stringers){
        alert('Stringers can not be blank.')
        return false                                             
    }
    setMsg('')
   
      var formData = new FormData();
      formData.append('slug', inputData.slug);
      formData.append('remarks', inputData.remarks);
      formData.append('event_type', inputData.event_type);
      formData.append('approved_by', inputData.approved_by);
      formData.append('added_by', localStorage && localStorage.userId ? localStorage.userId : null);
      formData.append('stringers', [parseInt(inputData.stringers)]);

      const getStateApi =`https://stingers.ianswire.in/api/events/add/`; 
        axios({
        method: "post",
        url: getStateApi,
        data: formData,
        headers: { 
            "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,

        }}).then((res) => {
        if(res && res.data && res.data.id && res.data.id){
           
            history.push('/event-list/');
        }

  


}) .catch((error) => {
  if(error && error.response && error.response.data && error.response.data.detail){
    alert(error.response.data.detail);
  }
  console.error("Error uploading image: ", error);
})}

    const getStringerData = () =>{

            const imSearchAPI = `https://stingers.ianswire.in/api/stringers/`;
            axios.get(
            imSearchAPI,
            {
                headers: {
                Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,
                },
            }
            ).then((res) => {
            const allStrngData =
                res.data.results &&
                res.data.results.length > 0 &&
                res.data.results.map((item) => {
                return {
                account_no: item.account_no,
                district : item.district,
                email : item.email,
                id : parseInt(item.id),
                ifsc_code : item.ifsc_code,
                name: item.name,
                number : item.number,
                state: item.state,
                uniqueID : item.uniqueID
                    
                };
                });
            if(res && res.data.results && res.data.results && res.data.results.length > 0 ){
            setStringData(allStrngData);
            // console.log('vbb',imgSearchD);
            }

            //console.log('TMT-varun', res);
            });
        
    }
    const [inputSearch, setSearch] = useState()
    const inputSData=(event)=>{
        setSearch(event.target.value);
    }
    const onSubmit = (e) => {
        e.preventDefault()
        fetchData()
    }
    const [eventData, setEventData] = useState()
    const [current, setCurrent] = useState(1)
    const [total, setTotal] = useState(0)
    const[pageStatus, setPageStatus] = useState(false)
  
    const nextPage =()=>{
     if(pageStatus){
          setCurrent(current+1)
     }
      
    }
    const pervPage = ()=>{
      if(current > 1 && pageStatus){
          setCurrent(current-1)
      }
    }
    const updatePage = (id)=>{
      if(pageStatus){
          setCurrent(id)
      }
    }
    const formatDate = (dateString) => {
        const date = new Date(dateString);
      
        // Options to format the date
        const options = {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        };
      
        return date.toLocaleString('en-US', options);
      };
      
      
    const fetchData = async () => {
    
        let allStoryAPI =`https://stingers.ianswire.in/api/events/?page=${current}`; 
        if(inputSearch){
            allStoryAPI = allStoryAPI+`&search=${inputSearch}`
        }
    
          axios
          .get(
              allStoryAPI,{
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,
                }
              }
          )
          .then((res) => {
          const allStoryData =
              res.data.results &&
              res.data.results.length > 0 &&
              res.data.results.map((item) => {
              return {

                  id :item.id,
                  slug: item.slug,
                  remarks: item.remarks,
                  event_type: item.event_type,
                  date: item.date ? formatDate(item.date) : '',
                  stringers: item.stringers,
                  added_by: item.added_by,
                  approved_by: item.approved_by,
              }})
          setEventData(allStoryData)

          if(allStoryData && allStoryData.length > 0){
            setPageStatus(true)
        }
        if(res && res.data && res.data.count && res.data.count > 0){
            setTotal(res.data.count)
        }
    })
}     
     const getUserData = () =>{

        const userApi = `https://stingers.ianswire.in/api/users/list/`;
        axios
        .get(
            userApi
        )
        .then((res) => {
        const tempUserData =
            res.data.data &&
            res.data.data.length > 0 &&
            res.data.data.map((item) => {
            return {
                id: item.id,
                groups: item.groups,
                name: item.name,
                username: item.username
            };
            });
            setUserData(tempUserData);
        }); 
      
}
      useEffect(() => {
        getUserData();
        fetchData()
        getStringerData()
      }, [msg, current]);
       
    return(
        <>
            <Header />
            <div id="layoutSidenav">
              <Sidebar />
              <div id="layoutSidenav_content">
              <main>
                    <div className="container-fluid px-4 px-4-event">
                        <div className="card mb-4">
                            <div className="row mb-3 card-header rounded hed2">
                                <div className="col-lg-8 d-flex align-items-center ev-i"> 
                                    <a href="/add-event">
                                        <i className="fa fa-plus-square"></i>Add Event
                                    </a> 
                                </div>
                                <div className="col-lg-4"> 
                                    <div className="rounded">
                                        <form onSubmit={onSubmit}>
                                            <div className="p-1 bg-light rounded rounded-pill shadows mb-2">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <button id="button-addon2" type="submit" onClick={onSubmit} className="btn btn-link text-black"><i className="fa fa-search"></i> </button>
                                                    </div>
                                                    <input type="search" ref={inputRef} placeholder="Search...." onChange={inputSData} aria-describedby="button-addon2" className="form-control border-0 bg-light" value={inputSearch}/>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                            {/* <form onSubmit={onSubmit}>            
                                <input type="search" ref={inputRef} className="form-control" placeholder="Search...." onChange={inputSData} name="search" value={inputSearch}/>
                                <span className="input-group-text border-0" onClick={onSubmit}><i className="bi bi-search" aria-hidden="true"></i></span>
                            </form> */}
                            <table id="datatablesSimple1" className='table table-striped table-bordered table-hover'>
                                <colgroup dangerouslySetInnerHTML={{
                                        __html: `
                                        <col class="teb-col-1" />
                                        <col class="teb-col-2" />
                                        <col class="teb-col-3" />
                                        <col class="teb-col-4" />
                                        <col class="teb-col-5" />
                                        <col class="teb-col-6" />
                                        <col class="teb-col-7" />
                                        <col class="teb-col-8" />
                                    </colgroup>
                                        `
                                    }}/>
                                    <thead>
                                        <tr className='tr-hed-bg'>
                                            <th>S.N.</th>
                                            <th>Slug</th>
                                            <th>Remarks</th>
                                            <th>Event Type</th>
                                            <th>Added By</th>
                                            <th>Approved By</th>
											<th>Date</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {eventData && eventData.length > 0 && eventData.map((event, e)=>{
                                            return(
                                                <>
                                                    <tr key={e}>
                                                        <td>{event.id}</td>
                                                        <td>{event.slug}</td>
                                                        <td>{event.remarks.substring(0, 250)}...</td>
                                                        <td>{event.event_type}</td>
                                                        <td>{event.added_by}</td>
                                                        <td>{event.approved_by}</td>
                                                        <td>{event.date}</td>
                                                        <td className='edit-ev'><a href={`/edit-event/${event.id}`}><i className="fa-solid fa-pen-to-square"></i></a></td>  
                                                    </tr>                                                
                                                </>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                {total && total > 0 &&
                                  <nav aria-label="Page navigation example">
                                    <ul className="pagination">
                                        <li className="page-item">
                                        <a className="page-link" href="#" aria-label="Previous" onClick={pervPage}>
                                            <span aria-hidden="true">&laquo;</span>
                                        </a>
                                        </li>
                                    { current && current > 1  &&                                     
                                         <li className="page-item"><a className="page-link" href="#" onClick={()=>{
                                            updatePage(current-1)
                                         }}>{current-1}</a></li>
                                    } 
                                       <li className="page-item"><a className="page-link" href="#">{current}</a></li>
                                        <li className="page-item"><a className="page-link" href="#"onClick={()=>{
                                            updatePage(current+1)
                                         }}>{current+1}</a></li>
                                        <li className="page-item">
                                        <a className="page-link" href="#" aria-label="Next" onClick={nextPage}>
                                            <span aria-hidden="true">&raquo;</span>
                                        </a>
                                        </li>
                                    </ul>
                                </nav>
                                }
                            </div>
                        </div>
                    </div>
                </main>
                <Footer />
               </div>
            </div>
        </>
    )
}

export default EventList