import React,{useState, useEffect} from 'react';
import axios from 'axios';
import Header from './Header'
import Footer from './Footer'
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { useHistory } from 'react-router-dom';
import API_URL from './ApiData'
import Sidebar from './Sidebar';

const AddStringer =()=>{
    const StateList = [
        // States
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chhattisgarh",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttar Pradesh",
        "Uttarakhand",
        "West Bengal",
      
        // Union Territories (UTs)
        "Andaman and Nicobar Islands",
        "Chandigarh",
        "Dadra and Nagar Haveli and Daman and Diu",
        "Lakshadweep",
        "Delhi",
        "Puducherry",
        "Jammu and Kashmir",
        "Ladakh"
      ];
      
    const [msg, setMsg]= useState('')

    const history = useHistory()

    const[inputData, setInputData] = useState({
        account_no:  null,
        district:  '',
        email:  '',
        id:  null,
        ifsc_code:'',
        name: '',
        number:  '',
        state: '',
        uniqueID: '',
        pan_no: '',
        aadhar_no:'',
        bank_name:'',
        banking_name:'',
        doj:'',
        lwd:'',
        is_active:''
  })
 

  const handleOnChange = event => {
    const { name, value } = event.target;
    setInputData({ ...inputData, [name]: value });
  }
  const updateStringerData = ()=>{
    setMsg('')
   
      var formData = new FormData();
      formData.append('account_no', inputData.account_no);
      formData.append('district', inputData.district);
      formData.append('email', inputData.email);
      formData.append('ifsc_code', inputData.ifsc_code);
      formData.append('name', inputData.name);
      formData.append('number', inputData.number);
      formData.append('state', inputData.state);
      formData.append('pan_no', inputData.pan_no);
      formData.append('aadhar_no', inputData.aadhar_no);
      formData.append('bank_name', inputData.bank_name);
      formData.append('banking_name', inputData.banking_name);
      formData.append('doj', inputData.doj);
      formData.append('is_active', true);

      const getStateApi =`https://stingers.ianswire.in/api/stringers/add/`; 
        axios({
        method: "post",
        url: getStateApi,
        data: formData,
        headers: { 
            "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,

        }}).then((res) => {
        if(res && res.data && res.data.id && res.data.id){
           
            history.push('/stringer-list/');
        }

  


}) .catch((error) => {
  if(error && error.response && error.response.data && error.response.data.detail){
    alert(error.response.data.detail);
  }
  console.error("Error uploading image: ", error);
})}


      useEffect(() => {
      }, [msg]);
       
    return(
        <>
            <Header />
            <div id="layoutSidenav">
              <Sidebar />
              <div id="layoutSidenav_content">
              <main>
                    <div className="container-fluid px-4 px-4-event">
                        <div className="card mb-4">
                            <div className="card-header">
                                <i className="fas fa-table me-1"></i>
                                Add Stringer
                            </div>
                            <div className="card-body">
                              <form>
                              
                                  <div className="row mb-3 col-md-8 stringer-form">	
                                    <div className="stringer-form-title">
                                      <h2>Personal Details</h2>
                                    </div>
                                      <div className="col-md-6 mb-3">
                                        <div className="form-group">
                                          <input type="text" placeholder="Name(As per Aadhaar):"className="form-control" name="name" onChange={handleOnChange} value={inputData.name}/>
                                        </div>
                                      </div>
                                      <div className="col-md-6 mb-3">
                                        <div className="form-group">
                                          <input type="text" placeholder="Aadhaar No:" className="form-control" name="aadhar_no" onChange={handleOnChange} value={inputData.aadhar_no}/>
                                        </div>
                                      </div>
                                      
                                      <div className="col-md-6 mb-3">
                                        <div className="form-group">
                                          <input type="text" name="district" placeholder="District:"  className="form-control"  onChange={handleOnChange} value={inputData.district}/>
                                        </div>
                                      </div>
                                      <div className="col-md-6 mb-3">
                                        <div className="form-group">
                                          <select className="form-control" placeholder="State:"  name="state"  onChange={handleOnChange} value={inputData.state}>
                                            <option value="">-- Select State --</option>
                                                                      {StateList && StateList.length > 0 && StateList.map((name, index) => (
                                                                          <option value={name.toUpperCase()}  key={index}>{name.toUpperCase()}</option>
                                                                      ))}
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-md-6 mb-3">
                                        <div className="form-group">
                                          <input type="number" name="number" placeholder="Contact Number:" className="form-control"  onChange={handleOnChange} value={inputData.number}/>
                                        </div>
                                      </div>
                                      <div className="col-md-6 mb-3">
                                        <div className="form-group">
                                          <input type="email" name="email" placeholder="Email" className="form-control"  onChange={handleOnChange} value={inputData.email}/>
                                        </div>
                                      </div>
                                      <div className="col-md-6 mb-3">
                                        <div className="form-group">
                                        <label htmlFor="inputTLCategory">Date of Joining </label>
                                          <input type="date" className="form-control" placeholder="Date of Joining:" name="doj" onChange={handleOnChange} value={inputData.doj}/>
                                        </div>
                                      </div>
                                      <div className="stringer-form-title">
                                        <h2>Banking Details</h2>
                                      </div>
                                      <div className="col-md-6 mb-3">
                                        <div className="form-group">
                                          <input type="text" className="form-control" placeholder="Name(As per Bank):" name="banking_name" onChange={handleOnChange} value={inputData.banking_name}/>
                                        </div>
                                      </div>
                                      <div className="col-md-6 mb-3">
                                        <div className="form-group">
                                          <input type="text" className="form-control" placeholder="Bank Name:" name="bank_name" onChange={handleOnChange} value={inputData.bank_name}/>
                                        </div>
                                      </div>
                                      <div className="col-md-6 mb-3">
                                        <div className="form-group">
                                          <input type="number" name="account_no" placeholder="Account Number:" className="form-control"  onChange={handleOnChange} value={inputData.account_no}/>
                                        </div>
                                      </div>
                                      <div className="col-md-6 mb-3">
                                        <div className="form-group">
                                          <input type="text" name="ifsc_code" placeholder="IFSC Code:" className="form-control"  onChange={handleOnChange} value={inputData.ifsc_code}/>
                                        </div>
                                      </div>
                                      <div className="col-md-6 mb-3">
                                        <div className="form-group">
                                          <input type="text" name="pan_no" placeholder="Pan No:"  className="form-control"  onChange={handleOnChange} value={inputData.pan_no}/>
                                        </div>
                                      </div>
                                        <div className="row mb-3 col-md-8">	
                                          <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
                                            <button className="btn btn-primary" type="button" onClick={updateStringerData}>Save</button>
                                          </div>
                                      </div>
                                    </div>
                              </form>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer />
               </div>
            </div>
        </>
    )
}

export default AddStringer