import React,{useState, useEffect, useRef} from 'react';
import axios from 'axios';
import Header from './Header'
import Footer from './Footer'
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import API_URL from './ApiData'
import Sidebar from './Sidebar';

const StringerList=()=>{
    const [msg, setMsg]= useState('')
    const [strinData, setStringData] = useState()
    const inputRef = useRef(null)
    const [current, setCurrent] = useState(1)
    const [total, setTotal] = useState(0)
    const[pageStatus, setPageStatus] = useState(false)
  
    const nextPage =()=>{
     if(pageStatus){
          setCurrent(current+1)
     }
      
    }
    const pervPage = ()=>{
      if(current > 1 && pageStatus){
          setCurrent(current-1)
      }
    }
    const updatePage = (id)=>{
      if(pageStatus){
          setCurrent(id)
      }
    }
    const getStringerData = () =>{

            
            let stringApi =`https://stingers.ianswire.in/api/stringers/?page=${current}`; 
            if(inputSearch){
                stringApi = stringApi+`&search=${inputSearch}`
            }
            axios.get(
                stringApi,
            {
                headers: {
                Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,
                },
            }
            ).then((res) => {
            const allStrngData =
                res.data.results &&
                res.data.results.length > 0 &&
                res.data.results.map((item) => {
                return {
                account_no: item.account_no,
                district : item.district,
                email : item.email,
                id : parseInt(item.id),
                ifsc_code : item.ifsc_code,
                name: item.name,
                number : item.number,
                state: item.state,
                uniqueID : item.uniqueID
                    
                };
                });
            if(res && res.data.results && res.data.results && res.data.results.length > 0 ){
            setStringData(allStrngData);
            // console.log('vbb',imgSearchD);
            }
            if(allStrngData && allStrngData.length > 0){
                setPageStatus(true)
            }
            if(res && res.data && res.data.count && res.data.count > 0){
                setTotal(res.data.count)
            }
            //console.log('TMT-varun', res);
            });
        
    }
    const [inputSearch, setSearch] = useState()
    const inputSData=(event)=>{
        setSearch(event.target.value);
    }
    const onSubmit = (e) => {
        e.preventDefault()
        getStringerData()
    }

      useEffect(() => {
  
        getStringerData()
      }, [msg, current]);
       
    return(
        <>
            <Header />
            <div id="layoutSidenav">
              <Sidebar />
              <div id="layoutSidenav_content">
              <main>
                    <div className="container-fluid px-4 px-4-event">
                        <div className="card mb-4">
                        <div className="row mb-3 card-header rounded hed2">
                                <div className="col-lg-8 d-flex align-items-center ev-i"> 
                                    <i className="fa-solid fa-user-group"></i> Stringer List
                                </div>
                                <div className="col-lg-4">
                                    <div className="rounded">
                                        <form onSubmit={onSubmit}>
                                            <div className="p-1 bg-light rounded rounded-pill shadows mb-2">
                                                <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <button id="button-addon2" type="submit" onClick={onSubmit} className="btn btn-link text-black"><i className="fa fa-search"></i> </button>
                                                </div>
                                                <input type="search" ref={inputRef} placeholder="Search...." onChange={inputSData} aria-describedby="button-addon2" className="form-control border-0 bg-light" value={inputSearch}/>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                {/* <form onSubmit={onSubmit}>            
                                    <input type="search" ref={inputRef} className="form-control" placeholder="Search...." onChange={inputSData} name="search" value={inputSearch}/>
                                    <span className="input-group-text border-0" onClick={onSubmit}><i className="bi bi-search" aria-hidden="true"></i></span>
                                </form> */}
                                <table id="datatablesSimple1" className='table table-striped table-bordered'>
                                    <thead>
                                        <tr className='tr-hed-bg'>
                                           <th>Name</th>
                                            <th>Unique ID</th>
                                            <th>District</th>
                                            <th>State</th>
                                            <th>Number</th>
											<th>Email</th>
                                            {/* <th>Account No</th>
                                            <th>IFSC Code</th>     */}
                                           { localStorage && localStorage.length > 0 && localStorage.access &&
                                            <th>Action</th>   }                                      
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {strinData && strinData.length > 0 && strinData.map((string, s)=>{
                                            return(
                                                <>
                                                    <tr key={s}>
                                                        <td className='user-pro'><a href={`/user-profile/${string.id}`} title="View Profile">{string.name}</a></td>
                                                        <td>{string.uniqueID}</td>
                                                        <td>{string.district}</td>
                                                        <td>{string.state}</td>
                                                        <td>{string.number}</td>
                                                        <td>{string.email}</td>
                                                        {/* <td>{string.account_no}</td>
                                                        <td>{string.ifsc_code}</td>    */}
                                                        {localStorage && localStorage.length > 0 && localStorage.access && 
                                                        <td className='edit-ev'><a href={`/edit-stringer/${string.id}`}><i className="fa-solid fa-pen-to-square"></i></a></td> 
                                                        }                                                   
                                                    </tr>                                                
                                                </>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                {total && total > 0 &&
                                  <nav aria-label="Page navigation example">
                                    <ul className="pagination">
                                        <li className="page-item">
                                        <a className="page-link" href="#" aria-label="Previous" onClick={pervPage}>
                                            <span aria-hidden="true">&laquo;</span>
                                        </a>
                                        </li>
                                    { current && current > 1  &&                                     
                                         <li className="page-item"><a className="page-link" href="#" onClick={()=>{
                                            updatePage(current-1)
                                         }}>{current-1}</a></li>
                                    } 
                                       <li className="page-item"><a className="page-link" href="#">{current}</a></li>
                                        <li className="page-item"><a className="page-link" href="#"onClick={()=>{
                                            updatePage(current+1)
                                         }}>{current+1}</a></li>
                                        <li className="page-item">
                                        <a className="page-link" href="#" aria-label="Next" onClick={nextPage}>
                                            <span aria-hidden="true">&raquo;</span>
                                        </a>
                                        </li>
                                    </ul>
                                </nav>
                                }
                            </div>
                        </div>
                    </div>
                </main>
                <Footer />
               </div>
            </div>
        </>
    )
}

export default StringerList