import React,{useState, useEffect} from 'react';
import {NavLink, useHistory} from 'react-router-dom'
import axios from 'axios';
import API_URL from './ApiData'
import { useLocation, Redirect } from 'react-router-dom';

const Header =()=>{
    const [loginStatus, setLoginStatus] = useState(false);
    const locale = 'en';
    const [msg, setMsg] = useState('');
    const [currentPath, setCurrntPage] = useState('');
    const [userNamee, setLoginUsername] = useState('');
    const history = useHistory();
    const [csss, setcsss] = useState('modal fade')
    const [search, setSearch] = useState();
    const [createData, setCreateData] = useState({
      title:'',
      summary :'',
      assign_me : true
    })
    const handleOnChange = event => {
      const { name, value } = event.target;
      setCreateData({ ...createData, [name]: value });
    };
      const location = useLocation();
      const { from } = location.state || { from: { pathname: '/login/' } };
      const mailSendData = (data)=>{
       
         var currentDate = formatDateTime(); 

         var formMailData = new FormData()

         var tempTitle = data.title.replace("\r\n", " ");
         
         formMailData.append('title',"New Story: "+ tempTitle)
         formMailData.append('description', "Created_at : "+currentDate+"\r\n Created_By : "+ localStorage.userName+"\r\n \r\n"+"Summary : "+data.summary)
         //formMailData.append('created_at', data.created_at)
     
         const mailApi = `https://cms.iansnews.in/api/email-before-edit/`; 
         axios({
           method: "post",
           url: mailApi,
           data: formMailData,
           headers: { 
             "Content-Type": "multipart/form-data",
           Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,
         },
         }).then((res) => {
           if(res.data && res.data.message && res.data.message == "Email sent successfully"){
            setMsg('')
            setCreateData({
              assign_me : true,
              title : '',
              summary : ''
            })
            setcsss('modal fade');
            setTimeout(
              window.location.href = "/", 10000);
           }
           

         })
    
    } 
    const formatDateTime = () => {
      const dateString = new Date();
      const date = new Date(dateString);
    
      // Extracting parts of the date
      const day = String(date.getDate()).padStart(2, '0'); // Get day and pad it to 2 digits
      const month = date.toLocaleString('default', { month: 'short' }); // Get month as short name
      const year = date.getFullYear(); // Get full year
    
      // Formatting the time
      const options = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      };
      const timeString = new Intl.DateTimeFormat('en-US', options).format(date);
    
      return `${day} ${month} ${year}, ${timeString}`;
    };
      const CreateStory = ()=>{
        var formData = new FormData();
        if(!createData.title){
          setMsg('Title can not be blank.')
        }else if(!createData.summary){
          setMsg('Summary can not be blank.')
        }else{
          formData.append('assign_me', createData.assign_me);
          formData.append('title', createData.title);
          formData.append('summary', createData.summary);
          const getStateApi =API_URL.Create_Story_URL; 
          axios({
            method: "post",
            url: getStateApi,
            data: formData,
            headers: { 
              "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,
          },
            
          }).then((res) => {

            setTimeout(
              createLoad(), 50000);
          });
        }
        }
      const createLoad = ()=>{
        // mailSendData(createData, '','')
     //  mailSendData(createData)
      }
      const openMadal = ()=>{
        setMsg('')
        setcsss('modal fade show');
      }
      useEffect(() => {
        if(!localStorage || !localStorage.accessToken){
            setLoginStatus(true);
            history.push('/login/');
        }
        if(location && location.pathname){
          let setCurPath = location.pathname.split("/");
          if(setCurPath && setCurPath.length > 1){
            setCurrntPage(setCurPath[1] ? setCurPath[1] : 'home');
          }
        }
        if(localStorage && localStorage.accessToken){
          setLoginUsername(localStorage.userName);
          }
      }, [localStorage]);
    return (
        <>
          <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">

                <NavLink className="navbar-brand ps-3" to="/"><img src="/assets/img/logo.png" className="stringer-logo"/> </NavLink>
                <button className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle" href="#!"><i className="fas fa-bars"></i></button>
                <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
                </form>
              
                {/* <ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4 ">
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" id="navbarDropdown" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-user fa-fw"></i></a>
                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                            <li><NavLink className="dropdown-item" to="/logout">Logout</NavLink></li>
                        </ul>
                    </li>
                </ul> */}
          </nav>
        </>
    )
}
export default Header;

