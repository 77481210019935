import React, { useState } from 'react';
import Select from 'react-select';

const jsonData = {
  "count": 171,
  "results": [
    {
      "id": 613,
      "name": "GOPAL CHATURVEDI (CHITTORGARH)",
      "uniqueID": "ians_amg_613",
      "district": "CHITTORGARH",
      "state": "RAJASTHAN",
      "number": "8302227292",
      "email": null,
      "is_active": true
    },
    {
      "id": 612,
      "name": "KAUSHOL SHRAMA (CHURU)",
      "uniqueID": "ians_amg_612",
      "district": "CHURU",
      "state": "RAJASTHAN",
      "number": "94146 02394",
      "email": "input@ians.in",
      "is_active": true
    },
    {
      "id": 611,
      "name": "ANANT DADHICH (BUNDI)",
      "uniqueID": "ians_amg_611",
      "district": "BUNDI",
      "state": "RAJASTHAN",
      "number": "9950812326",
      "email": "input@ians.in",
      "is_active": true
    }
  ]
};

const MultiSelectDropdown = () => {
  const [selectedOption, setSelectedOption] = useState(null); // Allow only single selection

  // Transforming the results array into the format react-select accepts
  const optionsTY = jsonData.results.map(person => ({
    value: person.id,
    label: person.name
  }));

  // Handle changes to the selected option
  const handleSelectChange = (selected) => {
    setSelectedOption(selected); // Update state with the latest selected option
    console.log("Selected option:", selected);
  };

  return (
    <div style={{ width: '300px', margin: '20px auto' }}>
      <h3>Select Person:</h3>
      <Select
        value={selectedOption}
        onChange={handleSelectChange}
        options={optionsTY}
        placeholder="Choose a person..."
        closeMenuOnSelect={true}  // Close the menu after selection
        isClearable // Add clearable functionality to remove the selected option
      />
      <div style={{ marginTop: '20px' }}>
        <h4>Selected ID:</h4>
        {selectedOption ? (
          <div>{selectedOption.label} (ID: {selectedOption.value})</div>
        ) : (
          <div>No person selected</div>
        )}
      </div>
    </div>
  );
};

export default MultiSelectDropdown;
