import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { useHistory } from 'react-router-dom';
import API_URL from './ApiData';
import Sidebar from './Sidebar';
import { useParams } from 'react-router-dom';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const StringerProfile = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [msg, setMsg] = useState('');
    const history = useHistory();
    const { editId } = useParams();

    const [userData, setUserData] = useState();
    const inputRef = useRef(null);

    const [strinData, setStringData] = useState();
    const [inputData, setInputData] = useState({
        account_no: null,
        district: '',
        email: '',
        id: null,
        ifsc_code: '',
        name: '',
        number: '',
        state: '',
        uniqueID: ''
    });

    const [eventData, setEventData] = useState();
    const [currMonth, setCurrMonth] = useState();
    const [currMonthName, setCurrMonthName] = useState('');

    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const currentYear = new Date().getFullYear();
    const formatDate = (dateString) => {
        const date = new Date(dateString);
      
        // Options to format the date
        const options = {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        };
      
        return date.toLocaleString('en-US', options);
      };
    const fetchDailyData = async () => {
        try {
            const headers = {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken : ''}`,
            };
            const response = await axios.get(`https://stingers.ianswire.in/api/events/daily-output-stringers/?stringer_id=${editId}`, { headers });
            const result = response.data;

            const transformedData = result.daily_output.map(item => ({
                date: item.date,
                eventsCovered: item.events_covered,
            }));

            setData(transformedData);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch data');
            setLoading(false);
        }
    };

    const getStringData = () => {
        const geteventApi = `https://stingers.ianswire.in/api/events/monthly-stringers/?stringer_id=${editId}`;
        axios.get(geteventApi, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken : ''}`,
            }
        }).then((res) => {
            if (res && res.data && res.data.results && res.data.results.length > 0) {
                const stringer = res.data.results[0];
                setInputData({
                    account_no: stringer.account_no || null,
                    name: stringer.name || '',
                    district: stringer.district || '',
                    email: stringer.email || '',
                    number: stringer.number || '',
                    state: stringer.state || '',
                    uniqueID: stringer.uniqueID || '',
                    ifsc_code: stringer.ifsc_code || '',
                });

                if (stringer.events && stringer.events.length > 0) {
                    const allStoryData = stringer.events.map(item => ({
                        id: item.id,
                        slug: item.slug,
                        remarks: item.remarks,
                        event_type: item.event_type,
                        date: item.date ? formatDate(item.date) : '',
                        stringers: item.stringers,
                        added_by: item.added_by,
                        approved_by: item.approved_by,
                    }));
                    setEventData(allStoryData);
                }
            }
        }).catch(error => {
            if (error && error.response && error.response.data && error.response.data.detail) {
                alert(error.response.data.detail);
            }
            console.error("Error fetching stringer data: ", error);
        });
    };

    const getCurrentMonthData = (monthlyData) => {
        const currentMonthIndex = new Date().getMonth();
        const currentMonthName = monthNames[currentMonthIndex];
        setCurrMonthName(currentMonthName);

        return monthlyData.find(data => data.month === currentMonthName);
    };

    const fetchData = async () => {
        const allStoryAPI = `https://stingers.ianswire.in/api/events/monthly-data/${currentYear}/${editId}`;
        axios.get(allStoryAPI, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken : ''}`,
            }
        }).then((res) => {
            const allMonthData = res.data.monthly_data.map(item => ({
                month: item.month,
                year: item.year,
                events: item.events
            }));
            const currentMonthData = getCurrentMonthData(allMonthData);
            setCurrMonth(currentMonthData);
        });
    };

    useEffect(() => {
        fetchDailyData();
        fetchData();
        getStringData();
    }, [msg]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <>
            <Header />
            <div id="layoutSidenav">
                <Sidebar />
                <div id="layoutSidenav_content">
                    <main>
                        <div className="container-fluid px-4 px-4-event">
                            <section className="section about-section gray-bg" id="about">
                                <div className="container">
                                    <div className="row align-items-center flex-row-reverse">
                                    
                                    <div className="col-lg-6">
                                            <div style={{ width: '100%', height: 400 }}>
                                                
                                                <ResponsiveContainer width="100%" height="100%">
                                                    <LineChart data={data}>
                                                        <CartesianGrid strokeDasharray="3 3" />
                                                        <XAxis dataKey="date" />
                                                        <YAxis />
                                                        <Tooltip />
                                                        <Legend />
                                                        <Line type="monotone" dataKey="eventsCovered" stroke="#8884d8" />
                                                    </LineChart>
                                                </ResponsiveContainer>
                                            </div>
                                        </div> 
                                        <div className="col-lg-6">
                                            <div className="about-text go-to">
                                                <h3 className="dark-color">{inputData.name}</h3>
                                                <h6 className="theme-color lead">{inputData.district}</h6>
                                                <div className="row about-list">
                                                    <div className="col-md-6">
                                                        <div className="media">
                                                            <label>Unique ID</label>
                                                            <p>{inputData.uniqueID}</p>
                                                        </div>
                                                        <div className="media">
                                                            <label>State</label>
                                                            <p>{inputData.state}</p>
                                                        </div>
                                                        <div className="media">
                                                            <label>Account No</label>
                                                            <p>{inputData.account_no}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="media">
                                                            <label>E-mail</label>
                                                            <p>{inputData.email}</p>
                                                        </div>
                                                        <div className="media">
                                                            <label>Phone</label>
                                                            <p>{inputData.number}</p>
                                                        </div>
                                                        <div className="media">
                                                            <label>IFSC Code</label>
                                                            <p>{inputData.ifsc_code}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                         
                                    </div>

                                    <div className="counter">
                                        <div className="row">
                                            <h2 className="text-center">{currMonthName}</h2>
                                            <div className="col-6 col-lg-3">			
                                                <div className="count-data text-center">
                                                    <h6 className="count h2" data-to={currMonth && currMonth.events && currMonth.events.total ? currMonth.events.total: 0
                                                    } data-speed={currMonth && currMonth.events && currMonth.events.total ? currMonth.events.total: 0
                                                    }>{currMonth && currMonth.events && currMonth.events.total ? currMonth.events.total: 0
                                                    }</h6>
                                                    <p className="m-0px font-w-600">Total</p>
                                                </div>
                                            </div>
                                            <div className="col-6 col-lg-3">
                                                <div className="count-data text-center">
                                                    <h6 className="count h2" data-to={currMonth && currMonth.events && currMonth.events.single_id ? currMonth.events.single_id: 0
                                                    } data-speed={currMonth && currMonth.events && currMonth.events.single_id ? currMonth.events.single_id: 0
                                                    }>{currMonth && currMonth.events && currMonth.events.single_id ? currMonth.events.single_id: 0
                                                    }</h6>
                                                    <p className="m-0px font-w-600">Single ID</p>
                                                </div>
                                            </div>
                                            <div className="col-6 col-lg-3">
                                                <div className="count-data text-center">
                                                    <h6 className="count h2" data-to={currMonth && currMonth.events && currMonth.events.multi_id ? currMonth.events.multi_id: 0
                                                    } data-speed={currMonth && currMonth.events && currMonth.events.multi_id ? currMonth.events.multi_id: 0
                                                    }>{currMonth && currMonth.events && currMonth.events.multi_id ? currMonth.events.multi_id: 0
                                                    }</h6>
                                                    <p className="m-0px font-w-600">Multi ID</p>
                                                </div>
                                            </div>
                                            <div className="col-6 col-lg-3">
                                                <div className="count-data text-center">
                                                    <h6 className="count h2" data-to={currMonth && currMonth.events && currMonth.events.on_demand ? currMonth.events.on_demand: 0
                                                    } data-speed={currMonth && currMonth.events && currMonth.events.on_demand ? currMonth.events.on_demand: 0
                                                    }>{currMonth && currMonth.events && currMonth.events.on_demand ? currMonth.events.on_demand: 0
                                                    }</h6>
                                                    <p className="m-0px font-w-600">On Demand</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </section>

                            <div className="card mb-4">
                            <div className="card-header">
                                <i className="fas fa-table me-1"></i>
                                Event List
                            </div>
                            <div className="card-body">
                            {/* <form onSubmit={onSubmit}>            
                                <input type="search" ref={inputRef} className="form-control" placeholder="Search...." onChange={inputSData} name="search" value={inputSearch}/>
                                <span className="input-group-text border-0" onClick={onSubmit}><i className="bi bi-search" aria-hidden="true"></i></span>
                            </form> */}
                                <table id="datatablesSimple111" className='table table-striped table-bordered'>
                                <colgroup dangerouslySetInnerHTML={{
                                        __html: `
                                        <col width="20%">
                                        <col width="40%">
                                        <col width="8%">
                                        <col width="8%">
                                        <col width="8%">
                                        <col width="8%">
                                        <col width="8%">
                                        `
                                    }}/>
                                    <thead>
                                        <tr className='tr-hed-bg'>
                                            <th>Slug</th>
                                            <th>Remarks</th>
                                            <th>Event Type</th>
                                            <th>Added By</th>
                                            <th>Approved By</th>
											<th>Date</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {eventData && eventData.length > 0 && eventData.map((event, e)=>{
                                            return(
                                                <>
                                                    <tr key={e}>
                                                        <td>{event.slug}</td>
                                                        <td>{event.remarks.substring(0, 250)}...</td>
                                                        <td>{event.event_type}</td>
                                                        <td>{event.added_by}</td>
                                                        <td>{event.approved_by}</td>
                                                        <td>{event.date}</td>
                                                        <td className='edit-ev'><a href={`/edit-event/${event.id}`}><i className="fa-solid fa-pen-to-square"></i></a></td>  
                                                    </tr>                                                
                                                </>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        </div>
                    </main>
                    <Footer />
                </div>
            </div>
        </>
    );
};

export default StringerProfile;
