import React,{useState, useEffect, useCallback} from 'react';
import {NavLink} from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const Sidebar=(props)=>{

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return(
        <>
            <div id="layoutSidenav_nav">
                <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
                    <div className="sb-sidenav-menu">
                        <div className="nav">
                            <div className="d-flex flex-column align-items-center text-center user-pro">
								<img src="/assets/img/user-icon.png" className="rounded-circle" width="70"/>
								<div className="mt-3">
									<h4>{localStorage && localStorage.length > 0 && localStorage.username ? localStorage.username.toUpperCase() : ''}</h4>
								</div>
							</div>
                            <a className="nav-link" href="/">
                                <div className="sb-nav-link-icon"><i className="fa-solid fa-house"></i></div>
                                Home
                            </a>
                            {/* <a className="nav-link" onClick={handleShow}>
                                <div className="sb-nav-link-icon"><i className="fa-solid fa-magnifying-glass-plus"></i></div>
                                Filters
                            </a> */}
                            <a className="nav-link" href="/event-list">
                                <div className="sb-nav-link-icon"><i className="fa fa-file"></i></div>
                                Event List
                            </a>
                           <a className="nav-link" href="/add-event">
                                <div className="sb-nav-link-icon"><i className="fa fa-plus-square"></i></div>
                                Add Event
                            </a>     
                            <a className="nav-link" href="/stringer-list">
                                <div className="sb-nav-link-icon"><i className="fa-solid fa-user-group"></i></div>
                                Stringer List
                            </a>
                           {localStorage && localStorage.length > 0 && localStorage.access &&
                            <a className="nav-link" href="/add-stringer">
                                <div className="sb-nav-link-icon"><i className="fa-solid fa-user-plus"></i></div>
                                Add Stringer
                            </a> 
                            }                                                    
                            <a className="nav-link" href="/logout">
                                <div className="sb-nav-link-icon"><i className="fa-solid fa-power-off"></i></div>
                                Logout
                            </a>
                        </div>
                    </div>
                    <div className="sb-sidenav-footer">
                        <div className="small-text">Copyright © 2024 Indo-Asian News Service (IANS) India Private Limited | Design and Development by IANS Software Services</div>
                    </div>
                </nav>
            </div>
        {/* <div className="col-xl-3 col-lg-8 mx-auto sticky-coloum-item">
            <div className="rt-sidebar sticky-wrap">
                    <div className="ad">
                    <a href="https://in.jooble.org/" target="_blank"><img className=" lazyloaded" src="/assets/Images/Jooble_logo.png" /></a>
                    </div>
                    <h2 className="rt-section-heading style-2 mb--30"><span className="rt-section-text">Latest News</span><span className="rt-section-dot"></span><span className="rt-section-line"></span></h2>
                <ul className="list-latest-news">
                {props && props.data && props.data.length > 0 && props.data.slice(0, 8).map((lt, k)=>{
                    return(
                        <li key = {k}>
                        <figure>
                            <figcaption className="tabtextarea-latest-news">
                                <NavLink to={lt.slug}>
                                    <h3 className="title">{lt.title}</h3>
                                </NavLink>
                            </figcaption>
                            <div className="img-area-latest-news">
                                <NavLink to={lt.slug}><img src={lt.image} alt={lt.image_caption} /></NavLink>
                            </div>
                        </figure>
                    </li>
                    )
                })}

            </ul>

            </div>
        </div>         */}
         {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            <div className="card-body">
                <form>
                    <div className="form-floating mb-3">
                    <input className="form-control" type="text" placeholder="Subject" name="subject" value=""/>
                    <label for="inputSubject">Subject</label>
                    </div>
                    <div className="row mb-3">
                    <div className="col-md-6">
                        <div className="form-floating mb-3 mb-md-0">
                        <select className="form-control" name="stringName">
                            <option value="">-- Select Stringer --</option>
                            <option value="613">GOPAL CHATURVEDI (CHITTORGARH)</option>
                            <option value="612">KAUSHOL SHRAMA (CHURU)</option>
                            <option value="611">ANANT DADHICH (BUNDI)</option>
                            <option value="610">DEEPAK PURI (BHARATPUR)</option>
                            <option value="609">MUKESH SHARMA (BEAWAR)</option>
                            <option value="608">DURG SINGH (BADMER)</option>
                            <option value="607">MUTTOJU SRINIVAS</option>
                            <option value="606">AMIR HUSSAIN (BANDA)</option>
                            <option value="605">PRITAM PANDEY (SAHIBGANJ)</option>
                            <option value="604">GANGADHAR (SARAIKELA)</option>
                            <option value="603">AMRIT (NAWADA)</option>
                            <option value="602">RAHUL KAPOOR (REASI KATRA)</option>
                            <option value="601">VINIT KAPOOR</option>
                            <option value="600">BHUPENDRA SAHU(BEMETARA)</option>
                            <option value="599">CHANDRA KANT TIWARI (KANPUR)</option>
                            <option value="598">KAPIL (ALWAR)</option>
                            <option value="597">BHARAT (DELHI)</option>
                            <option value="596">DEEPAK (DELHI)</option>
                            <option value="595">YOGENDRA MAHAWAR (KOTA)</option>
                            <option value="594">MANISH MISHRA (KUSHINAGAR)</option>
                        </select>
                        <label for="inputFirstName">Stringer Name</label>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-floating">
                        <select className="form-control" name="state">
                            <option value="">-- Select State --</option>
                            <option value="andhra pradesh">ANDHRA PRADESH</option>
                            <option value="arunachal pradesh">ARUNACHAL PRADESH</option>
                            <option value="assam">ASSAM</option>
                            <option value="bihar">BIHAR</option>
                            <option value="chhattisgarh">CHHATTISGARH</option>
                            <option value="goa">GOA</option>
                            <option value="gujarat">GUJARAT</option>
                            <option value="haryana">HARYANA</option>
                            <option value="himachal pradesh">HIMACHAL PRADESH</option>
                            <option value="jharkhand">JHARKHAND</option>
                            <option value="karnataka">KARNATAKA</option>
                            <option value="kerala">KERALA</option>
                            <option value="madhya pradesh">MADHYA PRADESH</option>
                            <option value="maharashtra">MAHARASHTRA</option>
                            <option value="manipur">MANIPUR</option>
                            <option value="meghalaya">MEGHALAYA</option>
                            <option value="mizoram">MIZORAM</option>
                            <option value="nagaland">NAGALAND</option>
                            <option value="odisha">ODISHA</option>
                            <option value="punjab">PUNJAB</option>
                            <option value="rajasthan">RAJASTHAN</option>
                            <option value="sikkim">SIKKIM</option>
                            <option value="tamil nadu">TAMIL NADU</option>
                            <option value="telangana">TELANGANA</option>
                            <option value="tripura">TRIPURA</option>
                            <option value="uttar pradesh">UTTAR PRADESH</option>
                            <option value="uttarakhand">UTTARAKHAND</option>
                            <option value="west bengal">WEST BENGAL</option>
                            <option value="andaman and nicobar islands">ANDAMAN AND NICOBAR ISLANDS</option>
                            <option value="chandigarh">CHANDIGARH</option>
                            <option value="dadra and nagar haveli and daman and diu">DADRA AND NAGAR HAVELI AND DAMAN AND DIU</option>
                            <option value="lakshadweep">LAKSHADWEEP</option>
                            <option value="delhi">DELHI</option>
                            <option value="puducherry">PUDUCHERRY</option>
                            <option value="jammu and kashmir">JAMMU AND KASHMIR</option>
                            <option value="ladakh">LADAKH</option>
                        </select>
                        <label for="inputState">State</label>
                        </div>
                    </div>
                    </div>
                    <div className="row mb-3">
                    <div className="col-md-6">
                        <div className="form-floating mb-3 mb-md-0">
                        <input className="form-control" type="date" placeholder="Start Date" name="startDate" value=""/>
                        <label for="inputDate">Start Date</label>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-floating mb-3 mb-md-0">
                        <input className="form-control" type="date" placeholder="End Date" name="endDate" value=""/>
                        <label for="inputDate">End Date</label>
                        </div>
                    </div>
                    </div>
                </form>
            </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Search
          </Button>
        </Modal.Footer>
      </Modal> */}
        </>
    )
}
export default Sidebar