import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const StringerDailyOutputGraph = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch data from the API
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Set headers
                const headers = {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken : ''}`,
                };

                // Make the API request using axios
                const response = await axios.get('https://stingers.ianswire.in/api/events/daily-output-stringers/?stringer_id=533', { headers });

                const result = response.data;
                
                // Transform data for recharts format
                const transformedData = result.daily_output.map(item => ({
                    date: item.date,
                    eventsCovered: item.events_covered,
                }));

                setData(transformedData);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch data');
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div style={{ width: '100%', height: 400 }}>
            <h2>Daily Output for {data.length > 0 ? data[0].month : 'September'} 2024</h2>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="eventsCovered" stroke="#8884d8" />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default StringerDailyOutputGraph;
