
import React,{useState, useEffect} from 'react';
import axios from 'axios';
import {Helmet} from "react-helmet";
import { useHistory } from "react-router-dom";
import { useLocation, Redirect } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import API_URL from './ApiData'

const Login = ()=>{
    const [errorMsg, setErrorMsg] = useState('');
    const[inputData, setInputData] = useState({
        username:'',
        password:''
    })
    const history = useHistory();
    const location = useLocation();
    const { from } = location.state || { from: { pathname: '/' } };
      const handleOnChange = event => {
        const { name, value } = event.target;
        setInputData({ ...inputData, [name]: value });
      };
        const loginUser = (e) => {
        e.preventDefault()
        if(!inputData.username){
            setErrorMsg('Username can not be blank');
        }else if(!inputData.password){
            setErrorMsg('Password can not be blank');

        }else{
            let formData = new FormData();
            formData.append('username', inputData.username);
            formData.append('password', inputData.password);
            axios.post(API_URL.Login_URL,inputData,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
            if(response && response.data && response.data && response.data.token){
                localStorage.setItem('accessToken', response.data.token? response.data.token :'');
                localStorage.setItem('username', response.data.username? response.data.username:'');
                localStorage.setItem('userId', response.data.user_id? response.data.user_id:'');

                if(response && response.data && response.data && response.data.groups && response.data.groups.length > 0){
                    localStorage.setItem('access', response.data.groups[0] && response.data.groups[0] =='admin' ? true :false );
                }
                // if(response && response.data && response.data && response.data.groups && response.data.groups.length > 1){
                //     localStorage.setItem('publishOpt', response.data.groups[1]? response.data.groups[1] :'');
                // }
                if(response && response.data && response.data.groups && response.data.groups.length > 0 && response.data.groups[0] == 'mt'){
                    history.push('/');
                }else{
                    history.push('/');
                }
            }
            }).catch(err =>{
                if(err && err.response && err.response.data 
                    && err.response.data.non_field_errors
                    && err.response.data.non_field_errors.length > 0
                    && err.response.data.non_field_errors[0]
                    ){
                     setErrorMsg(err.response.data.non_field_errors[0]);
                  }
            });            
        }
        }
      useEffect(() => {
        if(localStorage && localStorage.accessToken){
            history.push('/');
        }
        <Redirect to={from} />
      }, []);
    return(
        <>
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <link rel="icon" href="icon-new.ico" type="image/x-icon" />
          <link rel="shortcut icon" href="/theme/Dashboard/images/favicon.ico" type="image/x-icon" />
          <title>Login Dashboard</title>
          <meta name="description" content="Stay up-to-date with the latest Dashboard news and updates. Get the hottest gossips, rumors, movie reviews, and exclusive celebrity interviews from the captivating world of Hindi cinema. Your one-stop destination for everything Dashboard." />
        </Helmet>
     
        <div id="layoutAuthentication">
            <div id="layoutAuthentication_content">
                <main>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-5">
                                <div className="card shadow-lg border-0 rounded-lg mt-5">
                                    <div className="card-header"><h3 className="text-center font-weight-light my-4">Login</h3></div>
                                    <div className="card-body">
                                    <p style={{color:'red'}}>{errorMsg}</p>
                                        <form onSubmit={loginUser}>
                                            <div className="form-floating mb-3">
                                                <input className="form-control" name="username" onChange={handleOnChange} value={inputData.username} type="text" placeholder="name@example.com" />
                                                <label for="inputEmail">Email address</label>
                                            </div>
                                            <div className="form-floating mb-3">
                                                <input className="form-control" name="password" onChange={handleOnChange} value={inputData.password}  type="password" placeholder="Password" />
                                                <label for="inputPassword">Password</label>
                                            </div>
                                            <div className="form-check mb-3">
                                                <input className="form-check-input" id="inputRememberPassword" type="checkbox" value="" />
                                                <label className="form-check-label" for="inputRememberPassword">Remember Password</label>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
                                               
                                                <button className="btn btn-primary" type="submit" >Login</button>
                                            </div>
                                        </form>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            
        </div>
        </>
    )
}
export default Login