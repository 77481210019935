import React,{useState, useEffect, useRef} from 'react';
import axios from 'axios';
import Header from './Header'
import Footer from './Footer'
import {NavLink} from 'react-router-dom';
import {Helmet} from "react-helmet";
import Button from 'react-bootstrap/Button';
import API_URL from './ApiData'
import { useHistory } from 'react-router-dom'
import Sidebar from './Sidebar';
import Dropdown from 'react-bootstrap/Dropdown';
import Select from 'react-select';
import StateHistogram from './StateHistogram';
import StateWiseHistogram from './stateWiseHistogram';

const Home =()=>{
    const [show, setShow] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [currentMonth, setCurrentMonth] = useState('');
    const [currentYear, setCurrentYear] = useState('');
    const handleSelectChange = (selected) => {
        setSelectedOptions(selected || []); // Update state with selected options
        console.log("Selected options:", selected);
      };
    const handleToggle = (isOpen) => {
      setShow(isOpen);
    };
  
    const handleSelect = (eventKey) => {
      setShow(false); // Close the dropdown
      console.log(`Selected: ${eventKey}`);
    };

    const today = new Date(); // Get current date
    let month = today.getMonth(); // Get current month (0 = January, 1 = February, ..., 11 = December)
    let year = today.getFullYear(); // Get current year

    if (today.getDate() === 1) {
      // If today is the first day of the month, use the previous month
      month = month === 0 ? 11 : month - 1; // Adjust to December if current month is January
      year = month === 11 ? year - 1 : year; // Adjust to previous year if month is December
    }

    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June', 'July',
      'August', 'September', 'October', 'November', 'December'
    ];
  const history = useHistory()
  const [eventData, setEventData] = useState()
  const [inputSearch, setSearch] = useState()
  const inputRef = useRef(null)
  const [strinData, setStringData] = useState()

  const [current, setCurrent] = useState(1)
  const [total, setTotal] = useState(0)
  const[pageStatus, setPageStatus] = useState(false)
  const options = [
    {
      value: 0,
      label: 'Angular',
    },
    {
      value: 1,
      label: 'Bootstrap',
      disabled: true,
    },
    {
      value: 2,
      label: 'React.js',
    },
    {
      value: 3,
      label: 'Vue.js',
    },
    {
      label: 'backend',
      options: [
        {
          value: 4,
          label: 'Django',
        },
        {
          value: 5,
          label: 'Laravel',
        },
        {
          value: 6,
          label: 'Node.js',
        },
      ],
    },
  ]

  const StateList = [
    // States
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  
    // Union Territories (UTs)
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Lakshadweep",
    "Delhi",
    "Puducherry",
    "Jammu and Kashmir",
    "Ladakh"
  ];
  const[inputData, setInputData] = useState({
    state:  '',
    subject:  '',
    stringName:  '',
    startDate:  '',
    endDate:''
})
const [inputStData, setInputStData] = useState({
    endSDate:'',
    startSDate: ''
    
})

const handleOnChange = event => {
const { name, value } = event.target;
setInputData({ ...inputData, [name]: value });
}

const handleOnSChange = event => {
    const { name, value } = event.target;
    setInputStData({ ...inputStData, [name]: value });
}
  const nextPage =()=>{
   if(pageStatus){
        setCurrent(current+1)
   }
    
  }
  const pervPage = ()=>{
    if(current > 1 && pageStatus){
        setCurrent(current-1)
    }
  }
  const updatePage = (id)=>{
    if(pageStatus){
        setCurrent(id)
    }
  }
  const inputSData=(event)=>{
      setSearch(event.target.value);
  }
  const onSubmit = (e) => {
      e.preventDefault()
      fetchData()
  }
  const [sData, setSData] = useState()
  const formatDate = (dateString) => {
  const date = new Date(dateString);
  
    // Options to format the date
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
  
    return date.toLocaleString('en-US', options);
  };
const fetchSDwlData = async () => {
    

      let dwlStingAPI =`https://stingers.ianswire.in/api/export/stinger-events-report/?`; 

        dwlStingAPI = dwlStingAPI+`start_date=${inputStData.startSDate}`
        dwlStingAPI = dwlStingAPI+`&end_date=${inputStData.endSDate}`
             
        axios.get(
    dwlStingAPI,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken : ''}`,
      },
      responseType: 'blob', // Ensure the response is handled as a file
    }
  )
  .then((res) => {
    // Create a new Blob object using the response data
    const blob = new Blob([res.data], { type: 'application/octet-stream' });

    // Create a link element to download the blob
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'stinger-events-report.xlsx'; // Or any file name you want

    // Trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  })
        .then((res) => {
            let allcStoryData = [];
            let tempSData = 
            res.data.results &&
            res.data.results.length > 0 &&
            res.data.results.map((sItem) => {
            return {

                id :sItem.id,
                account_no: sItem.account_no,
                district: sItem.district,
                email: sItem.email,
                ifsc_code: sItem.ifsc_code,
                name: sItem.name,
                number: sItem.number,
                state: sItem.state,
                uniqueID : sItem.uniqueID
            }})
            setSData(tempSData)

            if (res.data.results && res.data.results.length > 0) {
                // Iterate through all results
                res.data.results.forEach(result => {
                    if (result.events && result.events.length > 0) {
                        // Combine all events from each result into the single array
                        result.events.forEach(item => {
                            allcStoryData.push({
                                id: item.id,
                                slug: item.slug,
                                remarks: item.remarks,
                                event_type: item.event_type,
                                date: item.date ? formatDate(item.date) : '',
                                stringers: item.stringers,
                                added_by: item.added_by,
                                approved_by: item.approved_by
                            });
                        });
                    }
                });
            }
        
            setEventData(allcStoryData);
        if(allcStoryData && allcStoryData.length > 0){
            setPageStatus(true)
        }
        if(res && res.data && res.data.count && res.data.count > 0){
            setTotal(res.data.count)
        }
            
        })
    }
  const clearSearchFilter=()=>{
    setInputData({
        state:  '',
        subject:  '',
        stringName:  '',
        startDate:  '',
        endDate:''
    })
  }
  const fetchSearchData = async () => {
    setEventData()
    setShow(false)
   // http://localhost:8000/api/events/monthly-stringers/?from_dte=2024-01-01&till_dte=2024-10-31&stringer_id=440&state=UTTAR
    // setPageStatus(true)
      let allStoryAPI =`https://stingers.ianswire.in/api/events/monthly-stringers/?`; 
    //   let allStoryAPI =`https://stingers.ianswire.in/api/events/?page=${current}`; 
      if(inputSearch){
          //allStoryAPI = allStoryAPI+`&search=${inputSearch}`
      }
     // if(inputData && inputData.stringName){
           allStoryAPI = allStoryAPI+`stringer_id=${inputData.stringName}`
     // }
    //  if(inputData && inputData.startDate){
        allStoryAPI = allStoryAPI+`&from_dte=${inputData.startDate}`
      //}   
      //if(inputData && inputData.endDate){
        allStoryAPI = allStoryAPI+`&till_dte=${inputData.endDate}`
      //}  
      //if(inputData && inputData.state){
        allStoryAPI = allStoryAPI+`&state=${inputData.state}`
     // }               
        axios
        .get(
            allStoryAPI,{
              headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,
              }
            }
        )
        .then((res) => {
            let allcStoryData = [];
            let tempSData = 
            res.data.results &&
            res.data.results.length > 0 &&
            res.data.results.map((sItem) => {
            return {

                id :sItem.id,
                account_no: sItem.account_no,
                district: sItem.district,
                email: sItem.email,
                ifsc_code: sItem.ifsc_code,
                name: sItem.name,
                number: sItem.number,
                state: sItem.state,
                uniqueID : sItem.uniqueID
            }})
            setSData(tempSData)

            if (res.data.results && res.data.results.length > 0) {
                // Iterate through all results
                res.data.results.forEach(result => {
                    if (result.events && result.events.length > 0) {
                        // Combine all events from each result into the single array
                        result.events.forEach(item => {
                            allcStoryData.push({
                                id: item.id,
                                slug: item.slug,
                                remarks: item.remarks,
                                event_type: item.event_type,
                                date: item.date ? formatDate(item.date) : '',
                                stringers: item.stringers,
                                added_by: item.added_by,
                                approved_by: item.approved_by
                            });
                        });
                    }
                });
            }
        
            setEventData(allcStoryData);
        if(allcStoryData && allcStoryData.length > 0){
            setPageStatus(true)
        }
        if(res && res.data && res.data.count && res.data.count > 0){
            setTotal(res.data.count)
        }
            
        })
    }
    const fetchData = async () => {
        // http://localhost:8000/api/events/monthly-stringers/?from_dte=2024-01-01&till_dte=2024-10-31&stringer_id=440&state=UTTAR
         // setPageStatus(true)
         //   let allStoryAPI =`https://stingers.ianswire.in/api/events/monthly-stringers/`; 
           let allStoryAPI =`https://stingers.ianswire.in/api/events/?page=${current}`; 
           if(inputSearch){
               //allStoryAPI = allStoryAPI+`&search=${inputSearch}`
           }
           if(inputData && inputData.stringName){
                allStoryAPI = allStoryAPI+`?stringer_id=${inputData.stringName}`
           }
           if(inputData && inputData.startDate){
             allStoryAPI = allStoryAPI+`&from_dte=${inputData.startDate}`
           }   
           if(inputData && inputData.endDate){
             allStoryAPI = allStoryAPI+`&till_dte=${inputData.endDate}`
           }  
           if(inputData && inputData.state){
             allStoryAPI = allStoryAPI+`&state=${inputData.state}`
           }               
             axios
             .get(
                 allStoryAPI,{
                   headers: {
                       'Content-Type': 'multipart/form-data',
                       Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,
                   }
                 }
             )
             .then((res) => {
             const allStoryData =
                 res.data.results &&
                 res.data.results.length > 0 &&
                 res.data.results.map((item) => {
                 return {
     
                     id :item.id,
                     slug: item.slug,
                     remarks: item.remarks,
                     event_type: item.event_type,
                     date: item.date ? formatDate(item.date) : '',
                     stringers: item.stringers,
                     added_by: item.added_by,
                     approved_by: item.approved_by,
                 }})
             setEventData(allStoryData)
             if(allStoryData && allStoryData.length > 0){
                 setPageStatus(true)
             }
             if(res && res.data && res.data.count && res.data.count > 0){
                 setTotal(res.data.count)
             }
                 
             })
         }   
         
    const [searchDate, setSearchDate] = useState({
    startDate: '',
    endDate: ''
    })
    const handleOnSearChange=(event)=>{
        //setSearchDate(event.target.value);
        const { name, value } = event.target;
        setSearchDate({ ...searchDate, [name]: value });
    }
    
    const clearSearchdate = ()=>{
        setSearchDate({
         startDate: '',
         endDate: ''
        })
    }
    const getStringerData = () =>{

            
        let stringApi =`https://stingers.ianswire.in/api/stringers/?page=${current}`; 
        if(inputSearch){
            stringApi = stringApi+`&search=${inputSearch}`
        }
        axios.get(
            stringApi,
        {
            headers: {
            Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,
            },
        }
        ).then((res) => {
        const allStrngData =
            res.data.results &&
            res.data.results.length > 0 &&
            res.data.results.map((item) => {
            return {
            account_no: item.account_no,
            district : item.district,
            email : item.email,
            id : parseInt(item.id),
            ifsc_code : item.ifsc_code,
            name: item.name,
            number : item.number,
            state: item.state,
            uniqueID : item.uniqueID
                
            };
            });
        if(res && res.data.results && res.data.results && res.data.results.length > 0 ){
        setStringData(allStrngData);
        // console.log('vbb',imgSearchD);
        }
        if(allStrngData && allStrngData.length > 0){
            setPageStatus(true)
        }
        if(res && res.data && res.data.count && res.data.count > 0){
            setTotal(res.data.count)
        }
        //console.log('TMT-varun', res);
        });
    
} 
    useEffect(() => {
        setCurrentMonth(monthNames[month]);
        setCurrentYear(year);
        fetchData()
        getStringerData()
      }, [current, inputSearch]);
    return(
        <>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                <link rel="icon" href="icon-new.ico" type="image/x-icon" />
                <link rel="shortcut icon" href="/theme/Ianstv/images/favicon.ico" type="image/x-icon" />
                <meta data-meta-title="Ianstv.in" name="keywords" content="Ianstv news, Ianstv updates, Ianstv celebrities, Hindi cinema, Indian film industry, Ianstv gossips, Ianstv rumors, Ianstv releases, Movie reviews, Celebrity interviews" />
                <meta data-meta-title="Ianstv.in" name="description" content="Stay up-to-date with the latest Ianstv news and updates. Get the hottest gossips, rumors, movie reviews, and exclusive celebrity interviews from the captivating world of Hindi cinema. Your one-stop destination for everything Ianstv." />
            </Helmet>
            
            <Header />
            <div id="layoutSidenav">
              <Sidebar />
              <div id="layoutSidenav_content">
                  <main>
                    
                      <div className="container-fluid px-4 home-state-sec">
                            <div className="row">
                                <div className="card mb-5">
                                    <div className="row">
                                        <div className="card-header d-flex home-state-hed">
                                            <div className="align-items-center">
                                                <h2>State Event Summary ({currentMonth}-{currentYear})</h2>
                                            </div>
                                            <div className="date-form-col">
                                                <form className='date-form'> 
                                                        <div className="date-input">
                                                            <div className="form-floating mb-3 mb-md-0">
                                                            <input className="form-control" type="date" placeholder="Start Date" onChange={handleOnSearChange} value={searchDate.startDate} name="startDate"/>
                                                            <label for="inputDate">Start Date</label>
                                                            </div>
                                                        </div>
                                                        <div className="date-input">
                                                            <div className="form-floating mb-3 mb-md-0">
                                                            <input className="form-control" type="date" placeholder="End Date" onChange={handleOnSearChange} value={searchDate.endDate} name="endDate"/>
                                                            <label for="inputDate">End Date</label>
                                                            </div>
                                                        </div>
                                                        {/* <div className="date-input dropdown-home-btn">
                                                        <Dropdown>
                                                        <Dropdown.Toggle className="search-fil"  id="dropdown-basic">
                                                        
                                                             Stringer Data
                                                        </Dropdown.Toggle>
                                                                <Dropdown.Menu className="filters-box" >
                                                                    <div className="card-body">
                                                                        <form>
                                                                            <div className="row mb-3">
                                                                                <div className="col-md-6">
                                                                                    <div className="form-floating mb-3 mb-md-0">
                                                                                    <input className="form-control" type="date" placeholder="Start Date" onChange={handleOnSChange} value={inputStData.startSDate} name="startSDate"/>
                                                                                    <label for="inputDate">Start Date</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-floating mb-3 mb-md-0">
                                                                                    <input className="form-control" type="date" placeholder="End Date" onChange={handleOnSChange} value={inputStData.endSDate} name="endSDate"/>
                                                                                    <label for="inputDate">End Date</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="mt-4 mb-0">
                                                                                <div className="fil-search-btn">
                                                                                    <a className="btn btn-secondary btn-block" href="#" onClick={fetchSDwlData}>Download</a></div>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </Dropdown.Menu>
                                                            </Dropdown> 
                                                            </div>  */}
                                                        <div className="date-input">
                                                            <div className="form-ClearDate mb-3 mb-md-0">
                                                            <input className="form-control" type="submit" onClick={clearSearchdate} value={'Clear Date'} name="submit"/>
                                                        
                                                            </div>
                                                        </div>
                                                        <div className="date-input dropdown-home-btn">
                                                            <Dropdown>
                                                                <Dropdown.Toggle className="search-fil"  id="dropdown-basic">
                                                                <i className="fa-solid fa-download"></i>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className="filters-box" >
                                                                    <div className="card-body">
                                                                        <form>
                                                                            <div className="row mb-3">
                                                                                <div className="col-md-6">
                                                                                    <div className="form-floating mb-3 mb-md-0">
                                                                                    <input className="form-control" type="date" placeholder="Start Date" onChange={handleOnSChange} value={inputStData.startSDate} name="startSDate"/>
                                                                                    <label for="inputDate">Start Date</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div className="form-floating mb-3 mb-md-0">
                                                                                    <input className="form-control" type="date" placeholder="End Date" onChange={handleOnSChange} value={inputStData.endSDate} name="endSDate"/>
                                                                                    <label for="inputDate">End Date</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="mt-4 mb-0">
                                                                                <div className="fil-search-btn">
                                                                                    {/* <span>Clear filter</span> */}
                                                                                    <a className="btn btn-secondary btn-block" href="#" onClick={fetchSDwlData}>Download</a></div>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </Dropdown.Menu>
                                                            </Dropdown> 
                                                        </div>  
                                                </form> 
                                            </div>
                                        </div>
                                    </div>
                                                        
                                    <StateHistogram  startDate={searchDate && searchDate.startDate ? searchDate.startDate : ''} endDate={searchDate && searchDate.endDate ? searchDate.endDate : ''}/>
                                </div>
                                <div className="card mb-5">                                                             
                                    <StateWiseHistogram  startDate={searchDate && searchDate.startDate ? searchDate.startDate : ''} endDate={searchDate && searchDate.endDate ? searchDate.endDate : ''}/>
                                </div> 
                            </div>
                          </div>
                      
                  </main>
              </div>
            </div>
              <Footer />
            
        </>
    )
}

export default Home