import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const COLORS = ['#8884d8', '#df9b62', '#cb66c4', '#5ead7b'];



// Function to prepare data for the pie chart
const preparePieChartData = (stateSummary) => {
  // Aggregate the total counts for each category across all states
  const singleIdTotal = Object.values(stateSummary).reduce((acc, state) => acc + state.single_id, 0);
  const multiIdTotal = Object.values(stateSummary).reduce((acc, state) => acc + state.multi_id, 0);
  const onDemandTotal = Object.values(stateSummary).reduce((acc, state) => acc + state.on_demand, 0);
//   const totalEvents = Object.values(stateSummary).reduce((acc, state) => acc + state.total, 0);

  // Format the data for the PieChart
  return [
    { name: 'Single ID', value: singleIdTotal },
    { name: 'Multi ID', value: multiIdTotal },
    { name: 'On Demand', value: onDemandTotal },
    // { name: 'Total Events', value: totalEvents }
  ];
};

const StateWisePieChart = (props) => {
  const [data, setData] = useState(null);    // State to hold the API response
  const [loading, setLoading] = useState(true);  // Loading state
  const [error, setError] = useState(null);   // Error state
  const [selectedState, setSelectedState] = useState('DELHI'); // Default state for testing 
  const [currentMonth, setCurrentMonth] = useState('');
  const [currentYear, setCurrentYear] = useState('');
  const [singleData, setSingleData] = useState({
    total_single_id : null,
    total_multi_id:null,
    total_on_demand: null,
    total_events : null
  })
  // Fetch data from the API
  useEffect(() => {
    const today = new Date(); // Get current date
    let month = today.getMonth(); // Get current month (0 = January, 1 = February, ..., 11 = December)
    let year = today.getFullYear(); // Get current year

    if (today.getDate() === 1) {
      // If today is the first day of the month, use the previous month
      month = month === 0 ? 11 : month - 1; // Adjust to December if current month is January
      year = month === 11 ? year - 1 : year; // Adjust to previous year if month is December
    }

    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June', 'July',
      'August', 'September', 'October', 'November', 'December'
    ];

   
    const fetchData = async () => {
    let apiUr = `https://stingers.ianswire.in/api/state_stinger_report/?state_name=${selectedState}`;
    if(props && props.startDate && props.endDate){
        apiUr = apiUr+`&start_date=${props.startDate}&end_date=${props.endDate}`;
    }
      try {
        const response = await axios.get(apiUr,
          {
            headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken : ''}`,
        }}
          
        );
        console.log("API Response:", response.data); // Log the response for debugging
        setCurrentMonth(monthNames[month]);
        setCurrentYear(year);
        if(response.data && localStorage && localStorage.length > 0 && localStorage.access==45){
            setSingleData({
              "total_single_id": response.data.overall_totals.total_single_id*500,
              "total_multi_id": response.data.overall_totals.total_multi_id*300,
              "total_on_demand": response.data.overall_totals.total_on_demand*750,
              "total_events": response.data.overall_totals.total_multi_id*300+response.data.overall_totals.total_on_demand*750+response.data.overall_totals.total_single_id*500
            }); 
            setData(response.data);  // Store the data in state
          }else{
            setData(response.data);  // Store the data in state
          }
        setLoading(false);      
      } catch (err) {
        console.error("API Error:", err); // Log the error for debugging
        setError('Error fetching data');
        setData(null)
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedState, props]);

  if (loading) return <p>Loading...</p>;
  //if (error) return <p>{error}</p>;

  // Check if data exists and is valid
//   if (!data || !data.state_summary) {
//     return <p>No data available</p>;
//   }

  // Prepare data for the pie chart
  const pieChartData = preparePieChartData(data && data.state_summary ? data.state_summary : '');

  return (
    <>
    
       
        <div className='Stringers-chart-sec'>
             <div className='row'>  
                <div className="card-header d-flex">
                <div className="col-lg-8">
                        <h2>State-Wise Stringers Summary({currentMonth}-{currentYear})</h2>
                        
                    </div>   
                {/* Dropdown to select the state */}
                    <div className="col-lg-4 stringers-chart-sec-4col">
                        <div className='select'>
                        <select value={selectedState} onChange={(e) => setSelectedState(e.target.value)}>
                            <option value="Andhra Pradesh">Andhra Pradesh</option>
                            <option value="ARUNACHAL PRADESH">Arunachal Pradesh</option>
                            <option value="ASSAM">Assam</option>
                            <option value="BIHAR">Bihar</option>
                            <option value="CHHATTISGARH">Chhattisgarh</option>
                            <option value="GOA">Goa</option>
                            <option value="GUJARAT">Gujarat</option>
                            <option value="HARYANA">Haryana</option>
                            <option value="HIMACHAL PRADESH">Himachal Pradesh</option>
                            <option value="JHARKHAND">Jharkhand</option>
                            <option value="KARNATAKA">Karnataka</option>
                            <option value="KERALA">Kerala</option>
                            <option value="MADHYA PRADESH">Madhya Pradesh</option>
                            <option value="MAHARASHTRA">Maharashtra</option>
                            <option value="MANIPUR">Manipur</option>
                            <option value="MEGHALAYA">Meghalaya</option>
                            <option value="MIZORAM">Mizoram</option>
                            <option value="NAGALAND">Nagaland</option>
                            <option value="ODISHA">Odisha</option>
                            <option value="PUNJAB">Punjab</option>
                            <option value="RAJASTHAN">Rajasthan</option>
                            <option value="SIKKIM">Sikkim</option>
                            <option value="TAMIL NADU">Tamil Nadu</option>
                            <option value="TELANGANA">Telangana</option>
                            <option value="TRIPURA">Tripura</option>
                            <option value="UTTAR PRADESH">Uttar Pradesh</option>
                            <option value="UTTARAKHAND">Uttarakhand</option>
                            <option value="WEST BENGAL">West Bengal</option>
                            <option value="ANDAMAN AND NICOBAR ISLANDS">Andaman and Nicobar Islands</option>
                            <option value="CHANDIGARH">Chandigarh</option>
                            <option value="DADRA AND NAGAR HAVELI AND DAMAN AND DIU">Dadra and Nagar Haveli and Daman and Diu</option>
                            <option value="LAKSHADWEEP">Lakshadweep</option>
                            <option value="DELHI">Delhi</option>
                            <option value="PUDUCHERRY">Puducherry</option>
                            <option value="JAMMU KASHMIR">Jammu and Kashmir</option>
                            <option value="LADAKH">Ladakh</option>
                        </select>
                    </div>
                    </div> 
                </div>
            </div> 
        </div>
        <div className="row">
        
        <div  className="col-md-5 stringers-chart-col5">
         <div class="counter">
            <div className="row">
                <h2 className="text-center"></h2>
                <div className="col-6 col-lg-3">	
                    <div className="basic-card basic-card-lips">			
                        <div className="count-data text-center card-content">
                            <h6 className="count h2" data-to={singleData && singleData.total_events && singleData.total_events > 0 ? singleData.total_events : data &&data.overall_totals ? data.overall_totals.total_events: 0
                            } data-speed={singleData && singleData.total_events && singleData.total_events > 0 ? singleData.total_events : data &&data.overall_totals ? data.overall_totals.total_events: 0
                            }>{singleData && singleData.total_events && singleData.total_events > 0 ? singleData.total_events : data &&data.overall_totals ? data.overall_totals.total_events: 0
                            }</h6>
                        </div>
                        <div className="card-link">
                            <p className="m-0px font-w-600">Total</p>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-lg-3">
                <div className="basic-card basic-card-single">
                    <div className="count-data text-center card-content">
                    <h6 className="count h2" data-to={singleData && singleData.total_single_id && singleData.total_single_id > 0 ? singleData.total_single_id : data &&data.overall_totals ? data.overall_totals.total_single_id: 0
                        } data-speed={singleData && singleData.total_single_id && singleData.total_single_id > 0 ? singleData.total_single_id : data &&data.overall_totals ? data.overall_totals.total_single_id: 0
                        }>{singleData && singleData.total_single_id && singleData.total_single_id > 0 ? singleData.total_single_id : data &&data.overall_totals ? data.overall_totals.total_single_id: 0
                        }</h6>
                    </div>
                    <div className="card-link">
                        <p className="m-0px font-w-600">Single ID</p>
                    </div>
                </div>
                </div>
                <div className="col-6 col-lg-3">
                    <div className="basic-card basic-card-multi">
                        <div className="count-data text-center card-content">
                        <h6 className="count h2" data-to={singleData && singleData.total_multi_id && singleData.total_multi_id > 0 ? singleData.total_multi_id : data &&data.overall_totals ? data.overall_totals.total_multi_id: 0
                            } data-speed={singleData && singleData.total_multi_id && singleData.total_multi_id > 0 ? singleData.total_multi_id : data &&data.overall_totals ? data.overall_totals.total_multi_id: 0
                            }>{singleData && singleData.total_multi_id && singleData.total_multi_id > 0 ? singleData.total_multi_id : data &&data.overall_totals ? data.overall_totals.total_multi_id: 0
                            }</h6>
                        </div>
                        <div className="card-link">
                            <p className="m-0px font-w-600">Multi ID</p>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-lg-3">
                    <div className="basic-card basic-card-on-demand">
                        <div className="count-data text-center card-content">
                        <h6 className="count h2" data-to={singleData && singleData.total_on_demand && singleData.total_on_demand > 0 ? singleData.total_on_demand : data &&data.overall_totals ? data.overall_totals.total_on_demand: 0
                            } data-speed={singleData && singleData.total_on_demand && singleData.total_on_demand > 0 ? singleData.total_on_demand : data &&data.overall_totals ? data.overall_totals.total_on_demand: 0
                            }>{singleData && singleData.total_on_demand && singleData.total_on_demand > 0 ? singleData.total_on_demand : data &&data.overall_totals ? data.overall_totals.total_on_demand: 0}</h6>
                        </div>
                        <div className="card-link">
                            <p className="m-0px font-w-600">On Demand</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ResponsiveContainer width="100%" height={400}>
            <PieChart>
            <Pie
                data={pieChartData}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                outerRadius={150}
                fill="#8884d8"
                dataKey="value"
            >
                {pieChartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
            </Pie>
            <Tooltip />
            <Legend />
            </PieChart>
        </ResponsiveContainer>
        
        </div>
    <div className="col-md-7">
        {/* Show stringer data in a table format */}
    {data && data.stringer_summary && data.stringer_summary.length > 0 && (
    <div className='table-sgs'>
        <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
        <thead>
            <tr style={{ backgroundColor: '#f2f2f2' }}>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Id</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Name</th>
            {/* <th style={{ border: '1px solid #ddd', padding: '8px' }}>Unique ID</th> */}
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>District</th>
            <th style={{ border: '1px solid #ddd', padding: '6px' }}>Single ID Events</th>
            <th style={{ border: '1px solid #ddd', padding: '6px' }}>Multi ID Events</th>
            <th style={{ border: '1px solid #ddd', padding: '6px' }}>On Demand Events</th>
            <th style={{ border: '1px solid #ddd', padding: '6px' }}>Total Events</th>
            </tr>
        </thead>
        <tbody>
            {data.stringer_summary.map((stringer) => (
            <tr key={stringer.unique_id}>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{stringer.id}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}><a href={`/user-profile/${stringer.id}`} title="View Profile">{stringer.name}</a></td>
                {/* <td style={{ border: '1px solid #ddd', padding: '8px' }}>{stringer.unique_id}</td> */}
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{stringer.district}</td>
                <td style={{ border: '1px solid #ddd', padding: '6px' }}>{stringer.count_single_id}</td>
                <td style={{ border: '1px solid #ddd', padding: '6px' }}>{stringer.count_multi_id}</td>
                <td style={{ border: '1px solid #ddd', padding: '6px' }}>{stringer.count_on_demand}</td>
                <td style={{ border: '1px solid #ddd', padding: '6px' }}>{stringer.total_events}</td>
            </tr>
            ))}
        </tbody>
        </table>
    </div>
    )}
    </div>
    </div>
    </>

  );
};

export default StateWisePieChart;
